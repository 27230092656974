<template>
  <div>
    <CModal
        data-sel="mdl-add-prtn"
        title="New Vendor"
        size="xl"
        :show.sync="showModal"
        @update:show="closeAddModal"
    >
      <CAlert
          data-sel="mdl-add-prtn-error"
          v-if="error"
          color="danger"
          closeButton
          @update:show="removeError">
        {{error}}
      </CAlert>
      <VendorForm
          v-bind:vendor-in="vendor"
          v-on:update-item="updateItem"
          v-on:error="catchError"
      />
    </CModal>
  </div>
</template>

<script>
import gql from "graphql-tag";
import VendorForm from "@/apps/partners/components/VendorForm";
const clonedeep = require('lodash.clonedeep')

const createVendor = gql`
  mutation createVendor($vendor: VendorInput){
    createVendor(vendor: $vendor) {
      nid
      shortName
      activeFh
      activePapp
      operatesIn{
        englishName
      }
    }
  }
`

export default {
  name: "VendorAdd",
  components: {
    VendorForm
  },
  data(){
    return {
      vendor: {
        "activePapp": false,
        "activeFh": false,
        "hidden": false,
        "isFranchiseHq": false,
        "logo": {},
        "imCatalogueImage": {},
        "factSheetId": "1PzAtQMtfmKL1tPTKwm_HaKUdcCKZMIjoXQgEMBssT2g",
        "handoverAssignmentPrimaryInput": "delivery_region",
        "handoverAssignmentSecondaryInputFallback": true,
        "sendDailyReporting": false,
        "sendInstantReporting": false,
        "directHandoverTemplate": "prequal_2020",
        "brandingPlan": "basic",
        "filterPrice0To100k": true,
        "scorePrice0To100k": "high",
        "filterPrice100kTo150k": true,
        "scorePrice100kTo150k": "high",
        "filterPrice150kTo200k": true,
        "scorePrice150kTo200k": "high",
        "filterPrice200kTo250k": true,
        "scorePrice200kTo250k": "high",
        "filterPrice250kTo300k": true,
        "scorePrice250kTo300k": "high",
        "filterPrice300kTo350k": true,
        "scorePrice300kTo350k": "high",
        "filterPrice350kTo400k": true,
        "scorePrice350kTo400k": "high",
        "filterPrice400kTo450k": true,
        "scorePrice400kTo450k": "high",
        "filterPrice450kTo500k": true,
        "scorePrice450kTo500k": "high",
        "filterPrice500kTo550k": true,
        "scorePrice500kTo550k": "high",
        "filterPrice550kTo600k": true,
        "scorePrice550kTo600k": "high",
        "filterPrice600kToInf": true,
        "scorePrice600kToInf": "high",
        "filterSize0To75": true,
        "scoreSize0To75": "high",
        "filterSize75To100": true,
        "scoreSize75To100": "high",
        "filterSize100To120": true,
        "scoreSize100To120": "high",
        "filterSize120To140": true,
        "scoreSize120To140": "high",
        "filterSize140To160": true,
        "scoreSize140To160": "high",
        "filterSize160To180": true,
        "scoreSize160To180": "high",
        "filterSize180To200": true,
        "scoreSize180To200": "high",
        "filterSize200To225": true,
        "scoreSize200To225": "high",
        "filterSize225To250": true,
        "scoreSize225To250": "high",
        "filterSize250To275": true,
        "scoreSize250To275": "high",
        "filterSize275To300": true,
        "scoreSize275To300": "high",
        "filterSize300ToInf": true,
        "scoreSize300ToInf": "high",
        "scorePricePerSqm0To1000": "high",
        "scorePricePerSqm1000To1200": "high",
        "scorePricePerSqm1200To1400": "high",
        "scorePricePerSqm1400To1600": "high",
        "scorePricePerSqm1600To1800": "high",
        "scorePricePerSqm1800To2000": "high",
        "scorePricePerSqm2000To2200": "high",
        "scorePricePerSqm2200To2400": "high",
        "scorePricePerSqm2400To2600": "high",
        "scorePricePerSqm2600To2800": "high",
        "scorePricePerSqm2800To3000": "high",
        "scorePricePerSqm3000ToInf": "high",
        "filterUtilizationApartmentHouse": true,
        "filterUtilizationDetachedHouse": true,
        "filterUtilizationDuplex": true,
        "filterUtilizationTwoFamiliesHouse": true,
        "filterConstructionMethodBlockhouse": false,
        "filterConstructionMethodPrefab": false,
        "filterConstructionMethodSolid": false,
        "filterPropertyYes": true,
        "filterPropertyReserved": true,
        "filterPropertyExpected": true,
        "filterPropertyNo": true,
        "filterExpansionStagePriceReady": true,
        "filterExpansionStagePriceFitting": true,
        "filterExpansionStagePriceLego": true,
        "dailyReportingEmails": [],
        "instantReportingEmails": [],
        "anonymizationRequestEmails": [],
        "msSendHtmlInstantHandover": false,
        "msHtmlInstantHandoverEmails": [],
        "msHtmlInstantHandoverTemplate": "ms_html_instant_handover",
        "msSendCsvHandover": false,
        "msCsvHandoverEmails": [],
        "msCsvHandoverSchedule": "instant",
        "msCsvHandoverTemplate": "ms_csv_handover_massivhaus",
        "sendLeadDuplicateReporting": false,
        "leadDuplicateReportingEmails": [],
        "exclusiveLeadsHandoverEmails": [],
      },
      vendorTemplate: {
        "activePapp": false,
        "activeFh": false,
        "hidden": false,
        "isFranchiseHq": false,
        "logo": {},
        "imCatalogueImage": {},
        "factSheetId": "1PzAtQMtfmKL1tPTKwm_HaKUdcCKZMIjoXQgEMBssT2g",
        "handoverAssignmentPrimaryInput": "delivery_region",
        "handoverAssignmentSecondaryInputFallback": true,
        "sendDailyReporting": false,
        "sendInstantReporting": false,
        "directHandoverTemplate": "prequal_2020",
        "brandingPlan": "basic",
        "filterPrice0To100k": true,
        "scorePrice0To100k": "high",
        "filterPrice100kTo150k": true,
        "scorePrice100kTo150k": "high",
        "filterPrice150kTo200k": true,
        "scorePrice150kTo200k": "high",
        "filterPrice200kTo250k": true,
        "scorePrice200kTo250k": "high",
        "filterPrice250kTo300k": true,
        "scorePrice250kTo300k": "high",
        "filterPrice300kTo350k": true,
        "scorePrice300kTo350k": "high",
        "filterPrice350kTo400k": true,
        "scorePrice350kTo400k": "high",
        "filterPrice400kTo450k": true,
        "scorePrice400kTo450k": "high",
        "filterPrice450kTo500k": true,
        "scorePrice450kTo500k": "high",
        "filterPrice500kTo550k": true,
        "scorePrice500kTo550k": "high",
        "filterPrice550kTo600k": true,
        "scorePrice550kTo600k": "high",
        "filterPrice600kToInf": true,
        "scorePrice600kToInf": "high",
        "filterSize0To75": true,
        "scoreSize0To75": "high",
        "filterSize75To100": true,
        "scoreSize75To100": "high",
        "filterSize100To120": true,
        "scoreSize100To120": "high",
        "filterSize120To140": true,
        "scoreSize120To140": "high",
        "filterSize140To160": true,
        "scoreSize140To160": "high",
        "filterSize160To180": true,
        "scoreSize160To180": "high",
        "filterSize180To200": true,
        "scoreSize180To200": "high",
        "filterSize200To225": true,
        "scoreSize200To225": "high",
        "filterSize225To250": true,
        "scoreSize225To250": "high",
        "filterSize250To275": true,
        "scoreSize250To275": "high",
        "filterSize275To300": true,
        "scoreSize275To300": "high",
        "filterSize300ToInf": true,
        "scoreSize300ToInf": "high",
        "scorePricePerSqm0To1000": "high",
        "scorePricePerSqm1000To1200": "high",
        "scorePricePerSqm1200To1400": "high",
        "scorePricePerSqm1400To1600": "high",
        "scorePricePerSqm1600To1800": "high",
        "scorePricePerSqm1800To2000": "high",
        "scorePricePerSqm2000To2200": "high",
        "scorePricePerSqm2200To2400": "high",
        "scorePricePerSqm2400To2600": "high",
        "scorePricePerSqm2600To2800": "high",
        "scorePricePerSqm2800To3000": "high",
        "scorePricePerSqm3000ToInf": "high",
        "filterUtilizationApartmentHouse": true,
        "filterUtilizationDetachedHouse": true,
        "filterUtilizationDuplex": true,
        "filterUtilizationTwoFamiliesHouse": true,
        "filterConstructionMethodBlockhouse": false,
        "filterConstructionMethodPrefab": false,
        "filterConstructionMethodSolid": false,
        "filterPropertyYes": true,
        "filterPropertyReserved": true,
        "filterPropertyExpected": true,
        "filterPropertyNo": true,
        "filterExpansionStagePriceReady": true,
        "filterExpansionStagePriceFitting": true,
        "filterExpansionStagePriceLego": true,
        "dailyReportingEmails": [],
        "instantReportingEmails": [],
        "anonymizationRequestEmails": [],
        "msSendHtmlInstantHandover": false,
        "msHtmlInstantHandoverEmails": [],
        "msHtmlInstantHandoverTemplate": "ms_html_instant_handover",
        "msSendCsvHandover": false,
        "msCsvHandoverEmails": [],
        "msCsvHandoverSchedule": "instant",
        "msCsvHandoverTemplate": "ms_csv_handover_massivhaus",
        "sendLeadDuplicateReporting": false,
        "leadDuplicateReportingEmails": [],
        "exclusiveLeadsHandoverEmails": [],
      },
      vendorSave: {},
      error: null
    }
  },
  computed: {
    showModal: {
      get: function() {
        return this.$store.state.addModalShow
      },
      set: function (){
      }
    }
  },
  methods: {
    updateItem(data){
      this.vendorSave = data
    },
    closeAddModal(status, evt, accept){
      if (accept) {
        this.$apollo.mutate({
          mutation: createVendor,
          variables: {
            vendor: this.vendorSave
          },
        })
        .then(res => {
          this.$emit('add-item', res.data.createVendor);
          this.vendor = clonedeep(this.vendorTemplate);
          this.vendorSave = clonedeep(this.vendorTemplate);
          this.$store.commit('set', ['addModalShow', false]);
        })
        .catch(error => {
          console.error(error);
          this.error = error.message
        })
       }else{
        this.vendor = clonedeep(this.vendorTemplate);
        this.vendorSave = clonedeep(this.vendorTemplate);
        this.$store.commit('set', ['addModalShow', false]);
       }
    },
    catchError(event){
      this.error = event
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    }
  }
}
</script>
